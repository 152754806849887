.cart {
    padding: 4rem;
    max-width: 95%;
    margin: 0 auto;
}

.cart h2 {
    margin-bottom: 1rem;
}

.cart-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media (min-width: 768px) {
    .cart-content {
        flex-direction: row;
    }
}

.cart-items {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cart-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.cart-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.cart-item-details {
    flex-grow: 1;
}

.quantity-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.quantity-controls button {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
}

.quantity-controls button:hover {
    background-color: #e9e9e9;
}

.remove-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    background-color: #ff4d4d;
    color: white;
    cursor: pointer;
}

.remove-button:hover {
    background-color: #e60000;
}

.cart-summarys {
    flex: 1;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.cart-summarys h3 {
    margin-bottom: 1rem;
}

.checkout-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    width: 100%;
}

.checkout-button:hover {
    background-color: #0056b3;
}

.continue-shopping {
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    display: block; /* Ensure the link takes up the full width of its container */
    color: #007bff; /* Link color */
    border: 1px solid #007bff; /* Border to match the link color */
    border-radius: 4px; /* Optional: add rounded corners */
    margin-top: 1rem; /* Add some space above the link */
}

.continue-shopping:hover {
    background-color: #e9e9e9; /* Change background color on hover */
    color: #0056b3; /* Change text color on hover */
    border-color: #0056b3; /* Change border color on hover */
}

