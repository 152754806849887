/* src/AboutUs.css */
.about-us {
    padding: 20px;
}

.about-us__header {
    text-align: center;
    margin-bottom: 20px;
}

.about-us__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow columns to stack on smaller screens */
}

.about-us__row {
    display: flex;
    width: 100%;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.about-us__image {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%; /* Ensure image container doesn't overflow */
}

.about-us__image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.about-us__text {
    flex: 2;
    padding: 20px;
    max-width: 100%; /* Ensure text container doesn't overflow */
}

.about-us__section {
    margin-bottom: 20px;
}

.about-us__section h2 {
    margin-bottom: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .about-us__content {
        flex-direction: column; /* Stack columns vertically on smaller screens */
        align-items: stretch; /* Allow columns to take full width */
    }

    .about-us__row {
        flex-direction: column; /* Stack image and text vertically */
    }

    .about-us__image {
        margin-bottom: 20px; /* Add spacing between image and text */
    }
}

@media (max-width: 480px) {
    .about-us__header h1 {
        font-size: 24px; /* Adjust header font size for smaller screens */
    }

    .about-us__text {
        padding: 10px; /* Adjust padding for smaller screens */
    }
    
    .about-us__section h2 {
        font-size: 18px; /* Adjust section heading font size */
    }
}
