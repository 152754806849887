/* src/ModalForm.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-form {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content-form {
  background-color: #fefefe;
  padding: 10px;
  border: 1px solid #888;
  width: 60%;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 10px;
}

.close-form {
  color: #000;
  float: right;
  font-size: 30px;
  position: relative;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: row;
}

.image-column {
  flex: 1;
  padding: 10px;
  align-items: center;
}

.form-column {
  flex: 1;
  padding: 10px;
}

.image-pro {
  display: flex;
  justify-content: center;

  height: auto;
  width: 100%;
}

.image-pro img {
  width: 60%;
  height: auto;
  border: 1 px soli black;
  object-fit: cover;
  border-radius: 10px;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #aaa;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
}

.form-group-row {
  display: flex;
  justify-content: space-between;
}

.form-group-row .form-group {
  flex: 1;
  margin-right: 5px;
}

.form-group-row .form-group:last-child {
  margin-right: 0px;
}

.login-button {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.toggle-form {
  text-align: center;
  margin-top: 10px;
}

.toggle-button {
  background: none;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;
  border: none;
}

.toggle-button:hover {
  text-decoration: underline;
}

.price-align {
  display: flex;
  align-items: center;
  gap: 10px;
}

.price-align p {
  margin: 0;
  font-size: 12px;
}

.price-align p:first-child {
  color: red;

  text-decoration: line-through;
}

.price-align p:nth-child(2) {
  color: green;

  font-weight: bold;
  display: flex;
  align-items: center;
}

.price-align p:nth-child(2)::before {
  content: '₹';
  margin-right: 4px;
}

.price-align p:last-child {
  color: orange;
}

.product-details {
  margin-bottom: 20px;
}

.product-details p {
  font-family: barlow;
  font-weight: bold;
}

.product-colors {
  margin: 10px 0;
}

.color-palette {
  display: flex;
  gap: 10px;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.divider {
  margin: 20px 0;
}

.product-qty-align {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.product-sizes,
.product-quantity {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.product-sizes label,
.product-quantity label {

  margin-bottom: 5px;
  color: #333;
}

.product-sizes select,
.product-quantity input {
  width: 100%;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 12px;
  color: #333;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.product-sizes select:focus,
.product-quantity input:focus {
  border-color: #b8b9b9;
  outline: none;
}

.product-sizes select:hover,
.product-quantity input:hover {
  border-color: #aaadaf;
}

.product-quantity input {
  -moz-appearance: textfield;
}

.product-quantity input::-webkit-outer-spin-button,
.product-quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .modal-content-form {
    width: 90%;
    padding: 20px;
  }

  .modal-body {
    flex-direction: column;
  }

  .image-column,
  .form-column {
    padding: 10px 0;
  }

  .product-qty-align {
    flex-direction: column;
    gap: 10px;
  }
}


/* ModalForm.css */

.modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  /* Removed top and bottom padding */
  margin: 0;
  /* Ensure no extra margin is added */

  border-bottom: 1px solid #ddd;
}




.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}


.form-check {
  display: flex;
  align-items: center;
  /* Vertically centers the checkbox and text */
  margin-bottom: 10px;
}

.form-check label {
  display: flex;
  align-items: center;
  font-size: 16px;
  /* Adjust the font size as needed */
  color: #333;
  /* Adjust the text color as needed */
  cursor: pointer;
  /* Changes the cursor to a pointer when hovering over the label */
}

.form-check input[type="checkbox"] {
  margin-right: 8px;
  /* Space between the checkbox and the label text */
  width: 16px;
  /* Adjust the size of the checkbox if needed */
  height: 16px;
  /* Adjust the size of the checkbox if needed */
}