.offers-container {
    max-width: 95%;
    margin: 0 auto;
    padding: 20px;
    font-family: Barlow;
  
}

.offer {
    margin-bottom: 40px;
    margin-top: 30px;
    position: relative;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* Center the content of each offer */
}

.offer::before {
    content: 'Offer';
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 0 10px;
    transform: rotate(45deg);
}

.offer h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.offer-products {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.product-card {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: calc(25% - 40px);
    box-sizing: border-box;
    transition: transform 0.3s;
    margin: 10px;
    position: relative;
    text-align: center;
}

.product-card:hover {
    transform: translateY(-5px);
}

.product-img {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.product-info {
    text-align: center;
}

.product-price {
    margin-top: 10px;
    color: #28a745;
    font-size: 14px;
    
}

.product-name {
    margin: 10px 0;
    text-align: left;
    
}

.product-color {
    margin: 5px 0;
}

.timer {
    margin-bottom: 20px;
    font-size: 18px;
    color: #212fb1;
    border: 1px solid #212fb1;
    padding: 5px 10px;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    /* Align to center */
    margin-right: auto;
    /* Align to center */
}

.timer-label {
    font-size: 18px;
    color: #ff6600;
    margin-bottom: 10px;
}

.timer-values {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    font-size: 20px;
    color: #444;
}

.timer-values span {
    margin: 0 5px;
}

.timer-labels {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #ff6600;
}

.timer-labels span {
    margin: 0 15px;
}

h4 {
    margin: 10px 0;
}

p {
    margin: 5px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .product-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .product-card {
        width: calc(100% - 20px);
    }
}

.special-offer {
    background-color: red;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 20px 40px;
    position: relative;
    text-align: center;
    border-radius: 0 0 100px 20px;
}

.special-offer::before,
.special-offer::after {
    content: "";
    position: absolute;
    background-color: red;
    width: 0px;
    height: 0px;
    border-radius: 0%;
    top: 100%;
}

.special-offer::before {
    left: 0;
    transform: translateX(-50%);
}

.special-offer::after {
    right: 0;
    transform: translateX(50%);
}