/* Ensure the navbar height is consistent and responsive */
.navbar {
  background-color: #fff;
  padding: 0;
  border-bottom: 1px solid #ccc;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Barlow;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar__logo {
  flex: 1;
  display: flex;
  align-items: center;
}

.navbar__logo a {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Adjust the logo image height to match the navbar */
.logo-image {
  height: auto;
  max-height: 10rem;
  /* Ensure logo scales down properly */
  width: auto;
}

.navbar__links-icons {
  flex: 3;
  /* Adjust flex value to give more space to links */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  /* Reset margin */
  padding: 0;
  /* Reset padding */
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

.navbar__links li {
  white-space: nowrap;
  /* Prevent text wrapping */
}

.navbar__links li a {
  font-size: 0.8rem;
  color: #333;
  text-decoration: none;
}

.navbar__links li a:hover {
  text-decoration: underline;
}

.navbar__icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar__icons a {
  color: #333;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.navbar__icons a svg {
  width: 1.4rem;
  height: 1.4rem;
}

.cart-icon {
  position: relative;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

.search-form {
  display: inline-block;
  margin-right: 1rem;
}

.search-input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.search-button svg {
  width: 1.4rem;
  height: 1.4rem;
}

.user-dropdown {
  position: relative;
  z-index: 1000;
  /* Ensure the dropdown appears above other components */
}

.user-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

.user-button svg {
  width: 1.4rem;
  height: 1.4rem;
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #000;
  /* Add circle outline */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  white-space: nowrap;
  /* Prevent text wrapping */
  z-index: 2000;
  /* Ensure the dropdown appears above other components */
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
}

.dropdown-menu li a,
.dropdown-menu li button {
  font-size: 1rem;
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown-menu li a:hover,
.dropdown-menu li button:hover {
  background-color: #f5f5f5;
}

.dropdown-divider {
  height: 1px;
  background-color: #ccc;
  margin: 0.5rem 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar {
    display: none;
    /* Hide the navbar in mobile view */
  }

  .navbar-container {
    flex-direction: column;
  }

  .navbar__links-icons {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar__links {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .navbar__icons {
    margin-top: 1rem;
  }

  /* Ensure logo image scales down on smaller screens */
  .logo-image {
    height: 2.5rem;
    /* Adjust as needed for smaller screens */
  }
}