/* src/ContactUs.css */
.contact-us {
    padding: 20px;
}

.contact-us__header {
    text-align: center;
    margin-bottom: 20px;
}

.contact-us__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow columns to stack on smaller screens */
}

.contact-us__form {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 500px;
    margin-right: 20px;
}

.contact-us__form form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-us__form label {
    margin-bottom: 5px;
}

.contact-us__form input,
.contact-us__form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.contact-us__form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Full width */
}

.contact-us__form button:hover {
    background-color: #0056b3;
}

.contact-us__divider {
    width: 1px;
    background-color: #ddd;
    margin: 0 20px;
    height: 100%;
}

.contact-us__details {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
}

.contact-us__details p {
    margin-bottom: 10px;
}

.contact-us__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.contact-us__phone p {
    margin-left: 10px;
}

.contact-us__info {
    text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-us__content {
        flex-direction: column;
        align-items: stretch; /* Allow columns to take full width */
    }

    .contact-us__form {
        margin-right: 0;
        margin-bottom: 20px; /* Add spacing between columns */
    }

    .contact-us__divider {
        display: none; /* Hide the divider on small screens */
    }
}

@media (max-width: 480px) {
    .contact-us__header h1 {
        font-size: 24px; /* Adjust header font size for smaller screens */
    }

    .contact-us__form input,
    .contact-us__form textarea {
        font-size: 14px; /* Adjust font size for inputs and textarea */
    }

    .contact-us__form button {
        font-size: 14px; /* Adjust button font size */
    }
}
