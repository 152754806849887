/* Base styles */
.product-container {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
}

.product-section {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  /* Add flex-wrap to allow items to wrap */
}

.thumbnail-gallery {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.thumbnail-image {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  object-fit: cover;
}

.thumbnail-image.selected {
  border: 2px solid #007bff;
}

.image-gallery {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.product-details {
  flex: 1;
  padding: 20px;
}

.product-details small {
  display: block;
  color: #888;
}

.product-details h1 {
  font-size: 20px;
  margin: 10px 0;
}

.price {
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  align-items: baseline;
}

.current-price {
  color: green;
  margin-right: 10px;
}

.cut-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 5px;
}

.discount {
  color: orange;
}

.tax-info {
  font-size: 10px;
  color: #888;
  margin-top: 5px;
}

.rating-review {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.rating-review p {
  font-size: 14px;
  margin-left: 10px;
}

.sizes,
.colors,
.quantity,
.delivery {
  margin: 10px 0;
  font-size: 14px;
}

.size-button {
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.color-button {
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.size-button.selected,
.color-button.selected {
  border: 1px solid #007bff;
}

.quantity input {
  width: 50px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.delivery h3 {
  font-size: 14px;
  margin: 5px 0;
}

.delivery p {
  font-size: 14px;
  color: #555;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.add-to-cart-button,
.buy-now-button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  font-size: 18px;
}

.add-to-cart-button {
  background-color: #007bff;
  color: white;
}

.add-to-cart-button:hover {
  background-color: #0056b3;
}

.buy-now-button {
  background-color: green;
  color: white;
}

.buy-now-button:hover {
  background-color: darkgreen;
}

.product-description {
  margin-top: 20px;
}

.product-description h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.more-button {
  background-color: transparent;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .product-section {
    flex-direction: column;
    align-items: center;
  }

  .thumbnail-gallery {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .thumbnail-image {
    width: 60px;
    height: 60px;
    margin: 5px;
  }

  .product-details {
    padding: 10px;
  }

  .product-details h1 {
    font-size: 18px;
  }

  .price {
    font-size: 12px;
  }

  .rating-review p {
    font-size: 12px;
  }

  .sizes,
  .colors,
  .quantity,
  .delivery {
    font-size: 12px;
  }

  .size-button,
  .color-button {
    margin: 3px;
    padding: 3px 5px;
    font-size: 12px;
  }

  .quantity input {
    width: 40px;
    padding: 3px;
  }

  .button-container {
    margin-top: 10px;
  }

  .add-to-cart-button,
  .buy-now-button {
    padding: 10px;
    font-size: 16px;
  }

  .product-description h3 {
    font-size: 14px;
  }

  .more-button {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .product-details h1 {
    font-size: 16px;
  }

  .price {
    font-size: 10px;
  }

  .rating-review p {
    font-size: 10px;
  }

  .sizes,
  .colors,
  .quantity,
  .delivery {
    font-size: 10px;
  }

  .size-button,
  .color-button {
    font-size: 10px;
  }

  .add-to-cart-button,
  .buy-now-button {
    padding: 8px;
    font-size: 14px;
  }

  .product-description h3 {
    font-size: 12px;
  }

  .more-button {
    font-size: 10px;
  }
}










/* ProductSection.css */

.size-dropdown {
  width: 100%;
  /* Makes the dropdown take full width */
  padding: 10px;
  /* Adds some padding for better appearance */
  font-size: 16px;
  /* Adjusts font size */
  border: 1px solid #ccc;
  /* Adds a light border */
  border-radius: 4px;
  /* Rounds the corners slightly */
  background-color: #f9f9f9;
  /* Sets a light background color */
  appearance: none;
  /* Removes default dropdown arrow for customization */
  -webkit-appearance: none;
  /* For Safari */
  -moz-appearance: none;
  /* For Firefox */
  outline: none;
  /* Removes the outline on focus */
  cursor: pointer;
  /* Changes the cursor to pointer */
}

.size-dropdown:focus {
  border-color: #007bff;
  /* Changes the border color when focused */
  background-color: #fff;
  /* Makes background white on focus */
}

/* Optional: add a custom arrow to the dropdown */
.size-dropdown-container {
  position: relative;
}

.size-dropdown-container::after {
  content: "▼";
  /* Unicode for down arrow */
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevents the arrow from blocking clicks */
  font-size: 12px;
  /* Adjust the size of the arrow */
  color: #333;
  /* Arrow color */
}