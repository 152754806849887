/* src/ComingSoon.css */
.coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
    color: #333;
}

.coming-soon h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.coming-soon p {
    font-size: 1.5em;
}
