.add-banner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 0 10px;
    box-sizing: border-box;
}

.add-banner-image {
    width: 100%;
    max-width: 95%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .add-banner-container {
        margin: 10px 0;
        padding: 0 5px;
    }

    .add-banner-image {
        border-radius: 5px;
        box-shadow: none;
    }
}

@media (max-width: 480px) {
    .add-banner-container {
        margin: 5px 0;
        padding: 0 2px;
    }

    .add-banner-image {
        border-radius: 3px;
    }
}