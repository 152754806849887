/* src/Slider.css */
.slider-container {
  width: 100%;
  margin: auto;
  overflow: hidden;
  /* Hide any overflow */
  position: relative;
  /* Ensure absolute positioning of inner elements */
}

.slider-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  display: block;
  /* Ensure it behaves as a block element */
}

/* Responsive styles */
@media (max-width: 768px) {
  .slider-image {
    max-height: 300px;
    /* Adjust max-height for smaller screens */
  }
}

@media (max-width: 480px) {
  .slider-image {
    max-height: 200px;
    /* Further adjust max-height for very small screens */
  }
}