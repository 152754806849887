/* Base styles */
.tabs-container {
    border: 0.5px solid #cecece;
    margin: 0 auto;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.tab {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: color 0.3s, border-bottom 0.3s;
}

.tab.active {
    color: #007bff;
    border-bottom: 1px solid #007bff;
}

.tab:hover {
    color: #0056b3;
}

.content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.review-list {
    margin-bottom: 20px;
}

.review {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.review-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.review-form label {
    margin-bottom: 10px;
}

.review-form textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
}

.star-rating {
    display: flex;
    margin-bottom: 10px;
}

.star {
    margin-right: 5px;
    cursor: pointer;
}

.review-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.review-form button:hover {
    background-color: #0056b3;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .tab {
        font-size: 14px;
        padding: 8px 15px;
    }

    .content {
        padding: 15px;
    }

    .review-form textarea {
        height: 80px;
    }

    .review-form button {
        padding: 8px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .tab {
        font-size: 12px;
        padding: 5px 10px;
    }

    .content {
        padding: 10px;
    }

    .review {
        padding: 8px 0;
    }

    .review-form label {
        font-size: 12px;
    }

    .review-form textarea {
        height: 60px;
    }

    .star-rating {
        flex-direction: row;
        justify-content: space-between;
    }

    .star {
        margin-right: 3px;
        margin-bottom: 3px;
    }

    .review-form button {
        padding: 6px;
        font-size: 12px;
    }
}
