.user-order-details {
  padding: 20px;
}

.user-order-card {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.user-order-row {
  display: flex;
  flex-direction: column;
  /* Default to column for mobile */
}

.user-product-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.user-order-details-card {
  display: flex;
  flex-direction: column;
  /* Default to column for mobile */
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.user-card-content {
  display: flex;
  flex-direction: column;
  /* Default to column for mobile */
}

.user-order-image img {
  width: 100%;
  /* Make the image responsive */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
}

.text-content {
  padding-left: 0;
  /* Remove padding for mobile */
  padding-top: 10px;
  /* Add top padding for mobile */
}

.delivery-status {
  width: 100%;
  /* Full width for mobile */
  padding: 10px;
  border-left: none;
  /* Remove left border for mobile */
  border-top: 1px solid #ddd;
  /* Add top border for separation */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.delivery-status h2,
.delivery-status p {
  margin: 0 0 10px 0;
}

.delivery-status button {
  margin-top: auto;
}

.cancel-button {
  
}

.cancel-button:hover {
  background-color: #ff1a1a;
}

.order-total-price{
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff4d4d;
  color: white;
  font-family: barlow;
  font-size: 1.6em;
  cursor: pointer;
  margin-top: 10px;
}

/* Media Queries for Tablet and Desktop */
@media (min-width: 600px) {
  .user-order-row {
    flex-direction: row;
    /* Change to row for larger screens */
  }

  .user-order-details-card {
    flex-direction: row;
    /* Change to row for larger screens */
  }

  .user-card-content {
    flex-direction: row;
    /* Change to row for larger screens */
  }

  .user-order-image img {
    width: 150px;
    height: 150px;
  }

  .text-content {
    padding-left: 20px;
    padding-top: 0;
  }

  .delivery-status {
    width: 300px;
    border-left: 1px solid #ddd;
    border-top: none;
  }
}
