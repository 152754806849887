.image-text-row {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 20px;
    width: 95%; /* Set width to 95% */
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    font-family: Barlow;
 
}

.image-container {
    flex: 1;
    position: relative;
    border-radius: 20px; /* Rounded corners */
    overflow: hidden; /* Ensures rounded corners work properly */
}

.image {
    width: 100%; /* Ensure the image fills its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
}

.image-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 20px solid #ddd;
    transform-origin: top right;
}

.text-container {
    flex: 1;
    padding-left: 10px;
}

@media (max-width: 768px) {
    .image-text-row {
        flex-direction: column; /* Stack content vertically on smaller screens */
        padding: 15px; /* Increase padding for better spacing */
    }

    .image-container {
        border-radius: 10px 10px 0 0; /* Adjust border radius for top corners only */
    }

    .image-container::after {
        border-top-width: 10px; /* Decrease folded corner size on smaller screens */
    }

    .text-container {
        padding-left: 0; /* Remove left padding on text for better alignment */
        padding-top: 10px; /* Add top padding to separate image and text */
    }
}
