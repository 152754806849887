.utility-bar {
  background-color: #0073e6;
  /* Background color for the entire announcement bar */
  padding: 0rem 0;
  border-bottom: 1px solid #ccc;
  font-family: Barlow;


}

.color-scheme-4 {
  /* Add your styles here */
}

.gradient {
  /* Add your styles here */
}

.utility-bar--bottom-border {
  /* Add your styles here */
}

.page-width {
  max-width: 95%;
  margin: 0 auto;
  padding: 0 1rem;
}

.utility-bar__grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.announcement-bar {
  width: 100%;
  background-color: #0073e6;
  /* Background color for announcements */

}

.announcement-bar-slider {
  display: flex;
  align-items: center;
}

.slider-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.slider-button {
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  width: 1em;
  height: 1em;
  color: white;
}

.icon-arrow-left {
  transform: rotate(0deg);
  /* Ensure the icon points left */
}

.icon-arrow-right {
  transform: rotate(0deg);
  /* Ensure the icon points right */
}

.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slideshow__slide {
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  /* Smooth transition */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Ensure it fits the parent container */
}

.slideshow__slide.active {
  opacity: 1;
  position: relative;
}

.announcement-bar__announcement {
  text-align: center;

  padding: 1rem;
  /* Padding around the text */
}

.announcement-bar__message {
  margin: 0;

  color: #fff;
  /* Text color */
  white-space: nowrap;
  /* Prevent text wrapping */
  overflow: hidden;
  /* Hide overflow text */
  text-overflow: ellipsis;
  /* Add ellipsis to overflow text */
}

.h5 {
  font-size: 0.8rem;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid--1-col {
  flex: 1 1 100%;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .announcement-bar__message {
    font-size: 1rem;
    /* Adjust font size for mobile */
  }
}

/* Hide Navbar and Header on Mobile View
@media (max-width: 768px) {


  .header-hide {
    display: none;
  }
} */