.home {
  padding: 20px;
  margin-top: 30px;
  font-family: Barlow;
  
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  width: calc(25% - 40px);
  /* Adjust for margin and padding */
  box-sizing: border-box;
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
}

.product-images {
  width: 100%;
  height: 250px;
  object-fit: contain;
  /* Ensures the image fits within the specified size */
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.product-info {
  text-align: left;
}

.product-price {
  margin-top: 10px;
}

.discount {
  color: red;
  margin-left: 10px;
}

.view-all-container {
  text-align: center;
  margin-top: 20px;
}

.view-all-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.view-all-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .card {
    width: calc(100% - 20px);
  }
}

/* src/Home.css */

/* Existing styles... */

.card.placeholder {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  /* Adjust based on your card height */
}

.placeholder-image {
  width: 100%;
  height: 250px;
  /* Adjust based on your image height */
  background-color: #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
}

.placeholder-info {
  width: 100%;
}

.placeholder-line {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.placeholder-line.short {
  width: 60%;
}



/* Adjust other styles as needed */