.all-products {
    padding: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    cursor: pointer;
}

.card:hover {
    transform: scale(1.05);
}

.product-images {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.product-info {
    padding: 20px;
}

.product-info h3 {
    font-size: 18px;
    margin: 0 0 10px 0;
}

.description {
    margin: 0 0 10px 0;
}

.product-price {
    font-weight: bold;
    margin: 0 0 5px 0;
}

.cutted-price {
    text-decoration: line-through;
    color: #999;
    margin-left: 10px;
}

.discount {
    color: red;
    margin-left: 5px;
}

.inclusive-taxes {
    margin: 5px 0;
    font-size: 14px;
    color: #666;
}

.rating {
    font-size: 14px;
    color: #666;
}
