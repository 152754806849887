.checkout-order-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.checkout-order-row {
    display: flex;
    flex-direction: row;
    /* Changed to row to display in one row */
    justify-content: space-between;
}

/* Styling for title and summary sections */
.checkout-order-title,
.cart-summr {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    margin: 10px;
}

.checkout-order-title h2,
.cart-summr h2 {
    margin-bottom: 20px;
}

/* Order content layout */
.checkout-order-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.checkout-order-details,
.payment-method {
    margin-bottom: 20px;
}

/* Buttons styling */
.add-address-button,
.complete-order-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.add-address-button:hover,
.complete-order-button:hover {
    background-color: #0056b3;
}

/* Cart item layout */
.cart-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.cart-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 15px;
}

.cart-item-details {
    flex: 1;
}

/* Totals styling */
.totals {
    margin-top: 20px;
}

.totals p {
    margin-bottom: 10px;
}

/* Error message styling */
.error {
    color: red;
    font-size: 12px;
}

/* Order popup styling */
.order-popup {
    margin-top: 20px;
    padding: 15px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Modal styling */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    margin: auto;
    border-radius: 5px;
    position: relative;
}

.modal-content h3 {
    margin-top: 0;
}

.modal-content input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.modal-content button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.modal-content button:hover {
    background-color: #0056b3;
}

/* Close button styling */
.close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
}

/* Address card styles */
.address-card {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}

.address-info {
    flex: 1;
}

/* Radio button styles */
.radio-container {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
}

.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.radio-container input:checked~.checkmark {
    background-color: #007bff;
}

.radio-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.radio-container input:checked~.checkmark:after {
    display: block;
}

.radio-container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .checkout-order-row {
        flex-direction: column;
    }

    .checkout-order-title,
    .cart-summary {
        margin: 10px 0;
    }

    .cart-item {
        flex-direction: column;
        align-items: center;
    }

    .cart-item-image {
        margin: 0 0 10px 0;
    }

    .modal-content {
        width: 90%;
        max-width: 350px;
    }
}

@media (max-width: 480px) {
    .checkout-order-page {
        padding: 10px;
    }

    .checkout-order-title,
    .cart-summary {
        padding: 10px;
    }

    .add-address-button,
    .complete-order-button {
        padding: 8px;
    }

    .cart-item-image {
        width: 80px;
        height: 80px;
    }
}