.model-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.model {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    max-height: 80%;
    /* Adjust this value to control the modal height */
    overflow-y: auto;
    /* Scroll if content overflows */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center the content vertically */
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-row {

    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-row input {
    width: 48%;
}

.form-row input[type="text"],
.form-row input[type="email"],
.form-row input[type="password"] {
    padding: 10px;
    border: 1px solid #ccc;

    width: 100%;
    margin: 2px;
    box-sizing: border-box;
}

.submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.switch-text {
    text-align: center;
    margin-top: 15px;
    color: #007bff;
    cursor: pointer;
}

.switch-text:hover {
    text-decoration: underline;
}