/* src/Footer.css */
.footer {
  width: 100%;
  /* Full width */
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
  margin-top: 20px;
  /* Add top margin */
}

.footer-content {
  display: flex;
  flex-direction: row;
  /* Row layout for two columns */
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  max-width: 1200px;
  /* Center content and limit its width */
  margin: 0 auto;
  /* Center content horizontally */
}

.footer-column {
  flex: 1;
  /* Equal width for both columns */
  padding: 0 20px;
  /* Add padding between columns */
}

.address-column {
  text-align: left;
  /* Left align the address content */
}

.contact-details h2 {
  margin-bottom: 10px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-item i {
  margin-right: 10px;
  font-size: 18px;
}

.contact-item p {
  margin: 0;
}

.subscribe-column {
  text-align: left;
  /* Left align the subscribe content */
}

.subscribe-column h2 {
  margin-bottom: 10px;
}

.subscribe-column p {
  margin-bottom: 20px;
}

.email-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
  /* Full width input */
  max-width: 400px;
  /* Maximum width */
}

.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #0056b3;
}

.footer-bottom {
  border-top: 1px solid #444;
  padding: 10px 0;
  margin-top: 20px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    /* Stack columns vertically */
    align-items: center;
  }

  .footer-column {
    padding: 10px 0;
    /* Reduce padding */
    width: 100%;
    /* Full width for mobile */
    max-width: none;
    /* Remove max-width */
    text-align: center;
    /* Center text for mobile */
  }

  .email-input {
    width: 90%;
    /* Adjust width for mobile */
  }

  .subscribe-button {
    width: 90%;
    /* Adjust width for mobile */
  }

  .contact-item {
    flex-direction: column;
    align-items: center;
  }

  .contact-item i {
    margin-bottom: 5px;
  }
}