/* Rating.css */
.rating-container {
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 8px; /* Adding border radius */
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
  font-family: 'Barlow', sans-serif; /* Setting font family */
}

.ratings {
  display: flex; /* Display children in a row */
  justify-content: space-between; /* Evenly space children */
}

.average-rating,
.rating-breakdown {
  text-align: left;
  flex: 1; /* Each section takes equal width */
  padding: 20px; /* Add padding for spacing inside the sections */
  font-family: 'Barlow', sans-serif; /* Setting font family */
}

.average-rating .rating {
  font-size: 24px;
  margin: 10px 0;
}

.progress-bars {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 10px;
}

.progress-bar {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%; /* Ensure full width */
}

.progress-bar .stars {
  flex: 0 0 100px; /* Adjust width as needed */
}

.progress {
  flex: 1;
  height: 20px;
  background-color: #eee;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #ffc107; /* Adjust color as needed */
  transition: width 0.3s ease-in-out;
}

.percentage {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .ratings {
    flex-direction: column; /* Stack sections vertically on smaller screens */
  }

  .average-rating,
  .rating-breakdown {
    flex: none; /* Reset to none to allow sections to stack */
    width: 90%; /* Take full width on smaller screens */
    margin-bottom: 20px; /* Add space between sections */
  }

  .progress-bar .stars {
    flex: none; /* Reset star column width */
    width: auto; /* Allow stars to adjust based on content */
    margin-right: 10px; /* Add space between stars and progress bar */
  }
}
